import React, { useState } from 'react';
import { Link } from "react-router-dom";
import './SideMenu.scss'
import Assets from '../Layouts/Assets'
import RestrictionModal from "../RestrictionModal";
import Row from "react-bootstrap/Row";
import SearchBar from "../Search";
import Col from "react-bootstrap/Col";
import { NavDropdown } from "react-bootstrap";
import styles from "../../components/Menu/Menu.module.scss";

const SideMenu = ({ menu, waffleMenu }) => {
  const [show, setShow] = useState(false);
  const [showDrop, setShowDrop] = useState(false);
  const [showAnim, setShowAnim] = useState(false);
  const [wrapOpen, setWrapOpen] = useState(false);

  const menuToggle = () => {
    document.body.classList.toggle('mobMenu');
  };
  const removeBodyClass = () => {
    document.body.classList.remove('mobMenu');
  };

  const handleWaffleMenu = () => {
    setShowDrop(!showDrop);
    setShowAnim(!showAnim)

  }

  return (
    <ul className="nav-mobile">
      <li className="sm-swlogo">
        <Link to="/">
          <img src={Assets.swplogo} alt="SW Pals" />
        </Link>
      </li>
      <li className="search-waffle-container">
        {wrapOpen && <SearchBar setWrapOpen={setWrapOpen} />}


        <div
          className={styles.searchIcons}
          onClick={() => setWrapOpen(!wrapOpen)}
        >
          {wrapOpen ? (
            <i className={styles.search_close_icon}>
              {" "}
              <img src={Assets.searchCloseIcon} alt="data" />
            </i>
          ) : (
            <i className={styles.search_icon}>
              <img src={Assets.searchIcon} alt="data" />
            </i>
          )}
        </div>
        <NavDropdown
          className={showAnim ? `${styles.navDropdown} ${styles.showAnim}` : styles.navDropdown}
          flip={false}
          show={showDrop}
          title={<img src={Assets.squareDots} alt="data" />}
          id="basic-nav-dropdown"
          onToggle={handleWaffleMenu}
        >

          <Row xs={3} className={styles.logo_grid}>
            {waffleMenu &&
              waffleMenu.map((value, key) => {
                return (
                  <Col key={key} md={4} className={`${styles.logo_list} ${window.location.href === "value.link" ? 'disable' : 'active'}`}>
                    {" "}

                    <a href={value.link} target={value.text !== "SW Pals" && "_blank"}>
                      <div className={styles.logo_box}>
                        <figure>
                          <img src={value.image} alt="data" />{" "}
                        </figure>
                        <h5>{value.text}</h5>
                      </div>
                    </a>
                  </Col>
                );
              })}
          </Row>
        </NavDropdown>
      </li>

      <li className="menu-container">
        <input id="menu-toggle" type="checkbox" />
        <label htmlFor="menu-toggle" onClick={() => menuToggle()} className="menu-button">
          <svg className="icon-open" viewBox="0 0 24 24"><path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path></svg>
          <svg className="icon-close" viewBox="0 0 100 100">
            <path d="M83.288 88.13c-2.114 2.112-5.575 2.112-7.69 0L53.66 66.188c-2.113-2.112-5.572-2.112-7.686 0l-21.72 21.72c-2.114 2.113-5.572 2.113-7.687 0l-4.693-4.692c-2.114-2.114-2.114-5.573 0-7.688l21.72-21.72c2.112-2.115 2.112-5.574 0-7.687L11.87 24.4c-2.114-2.113-2.114-5.57 0-7.686l4.842-4.842c2.113-2.114 5.57-2.114 7.686 0l21.72 21.72c2.114 2.113 5.572 2.113 7.688 0l21.72-21.72c2.115-2.114 5.574-2.114 7.688 0l4.695 4.695c2.112 2.113 2.112 5.57-.002 7.686l-21.72 21.72c-2.112 2.114-2.112 5.573 0 7.686L88.13 75.6c2.112 2.11 2.112 5.572 0 7.687l-4.842 4.84z" />
          </svg>
        </label>

        <ul className="menu-sidebar">
          {/* <li>
                <Link to="/" >HOME</Link>
              </li> */}
          {menu ? menu.map((value, key) => {
            return (
              <>
                {value.target !== '_blank' ?
                  <li key={key}>
                    <Link to={value.url} key={value.title} onClick={() => removeBodyClass()}>{value.title}</Link>
                  </li> : <li key={key}>
                    <Link onClick={() => setShow(true)} >
                      {value.title.toLowerCase()}
                    </Link>  <RestrictionModal show={show} setShow={setShow} url={value.url} />

                  </li>
                }
              </>
            )
          }) : null}




        </ul>
      </li>


    </ul>
  )
}

export default SideMenu;
