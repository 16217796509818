import React from 'react';
import Home from '../../components/Home';
import TitleComponent from '../../components/TitleComponent';

const HomePage = () => {
    return (
        <>
            <TitleComponent />
            <Home />
        </>
    )
}

export default HomePage;