/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback, useState, useRef } from "react";

import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { fetchMenu, fetchWaffleMenu } from "../../actions/menuAction";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import styles from "./Menu.module.scss";
import Assets from "../Layouts/Assets";
import SideMenu from "../SideMenu";
import SearchBar from "../Search";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import RestrictionModal from "../RestrictionModal";


const Menu = ({ fetchMenu, menu, fetchWaffleMenu, waffleMenu }) => {
  const wrapperRef = useRef(null);

  const [wrapOpen, setWrapOpen] = useState(false);
  const [show, setShow] = useState(false);

  const [showDrop, setShowDrop] = useState(false);
  let location = useLocation();

  const fetchCallback = useCallback(() => {
    fetchMenu();

  }, [fetchMenu]);

  const fetchCallback1 = useCallback(() => {

    fetchWaffleMenu();
  }, [fetchWaffleMenu]);


  useEffect(() => {
    if (menu.length === 0) {
      fetchCallback();
    }

    if (waffleMenu.length === 0) {
      fetchCallback1();
    }
  }, [menu, fetchCallback, waffleMenu]);

  const className = (url) => {
    if (
      location.pathname === url &&
      location.pathname?.includes(url.replace(new RegExp("s" + "*$"), ""))
    ) {
      return `${styles.menulink} ${styles.active}`;
    } else {
      return styles.menulink;
    }
  };

  useEffect(() => {
    if (!wrapOpen) {
      document.body.classList.remove('search-opened');
    }

  }, [wrapOpen]);

  const renderMenulist = () => {
    return (
      <div
        className={`${wrapOpen && styles.wrapOpenClass} ${styles.main_menu_wrap
          }`}
        ref={wrapperRef}
      >
        {menu
          ? menu.map((value, key) => {
            return (
              <>
                {value.target !== "_blank" ? (
                  <Link

                    to={value.url}
                    key={value.title}
                    className={className(value.url)}
                  >
                    {value.title.toLowerCase()}
                  </Link>
                ) : (

                  <div className={styles.donateMenu} key={key} >
                    <Link className={styles.donateLink} onClick={() => setShow(true)} >
                      {value.title.toLowerCase()}

                    </Link>  <RestrictionModal show={show} setShow={setShow} url={value.url} />

                  </div>


                )}

              </>
            );
          })
          : null}
      </div>
    );
  };

  const handleOpen = () => {
    setShowDrop(true);
  };

  const handleClose = () => {
    setShowDrop(false);
  };

  return (
    <div className={`${styles.topMenu} header-nav`} >
      <Navbar className={styles.topMenuLg}>
        <Container>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className={styles.menucontentbox}>
              {wrapOpen ? <SearchBar setWrapOpen={setWrapOpen} /> : renderMenulist()}

              <div
                className={styles.searchIcons}
                onClick={() => setWrapOpen(!wrapOpen)}
              >
                {wrapOpen ? (
                  <i className={styles.search_close_icon}>
                    {" "}
                    <img src={Assets.searchCloseIcon} alt="data" />
                  </i>
                ) : (
                  <i className={styles.search_icon}>
                    <img src={Assets.searchIcon} alt="data" />
                  </i>
                )}
              </div>
              <NavDropdown
                className={styles.navDropdown}
                flip={false}
                show={showDrop}
                title={<img src={Assets.squareDots} alt="data" />}
                id="basic-nav-dropdown"
                onMouseEnter={handleOpen}
                onMouseLeave={handleClose}
              >

                <Row md={3} xs={2} className={styles.logo_grid}>
                  {waffleMenu &&
                    waffleMenu.map((value) => {
                      return (
                        <Col md={4} className={`${styles.logo_list} ${window.location.href === "value.link" ? 'disable' : 'active'}`}>
                          {" "}

                          <a href={value.link} target={value.text !== "SW Pals" && "_blank"}>
                            <div className={styles.logo_box}>
                              <figure>
                                <img src={value.image} alt="data" />{" "}
                              </figure>
                              <h5>{value.text}</h5>
                            </div>
                          </a>
                        </Col>
                      );
                    })}
                </Row>
              </NavDropdown>

            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Navbar
        className={styles.topMenuSm}
        collapseOnSelect
        expand="lg"
        bg="light"
        variant="light"
      >
        <SideMenu menu={menu} waffleMenu={waffleMenu} />
      </Navbar>
    </div>
  );
};

const mapPropsToState = (state) => {
  return { menu: state.menu, waffleMenu: state.waffleMenu };
};

export default connect(mapPropsToState, { fetchMenu, fetchWaffleMenu })(Menu);
