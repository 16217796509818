import React, { useState } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import Assets from './Assets';
import styles from './Layouts.module.scss';
import { Link } from "react-router-dom";
import ShowsMenu from '../ShowsMenu';
import { useSpring, useTransition, animated } from 'react-spring'

import { useLocation } from "react-router-dom";

const MainLayout = ({ children, showMenu }) => {
  let location = useLocation();
  const [menuStatus, setMenuStatus] = useState(showMenu)
  const props = useSpring({
    to: [{ opacity: 1 }],
    from: { opacity: 0, marginTop: 0 }
  });
  const [props2, set, stop] = useSpring(() => ({
    config: { duration: 2000 },
    to: [{ opacity: 1 }],
    from: { opacity: 0 }
  }));



  const [showMenuDiv, setShowMenuDiv] = useState(true)

  const transitions = useTransition(menuStatus, null, {
    from: { transform: 'translate3d(0,-40px,0)' },
    enter: { transform: 'translate3d(0,0px,0)' },
    leave: { transform: 'translate3d(0,-40px,0)' },
  })


  const showMenuVist = (type) => {
    // set({opacity: 1,marginTop:5 })
    setMenuStatus(type)
  }

  return (
    <div className={styles.maincontent}>
      <header className={styles.main_header}>
        <div className={styles.swplogo}>
          <Link to="/">
            <img src={Assets.swplogo} alt="SW Pals" />
          </Link>
        </div>
        <animated.div style={props} className={`${styles.TopMenuWrapper} ${menuStatus && styles.sliderMenuVisible}`}><Header /></animated.div>
        <div className={`${styles.showMenuHolder} ${menuStatus && styles.showMenuInserted}`}>
          {menuStatus && menuStatus != 'none' && transitions.map(({ item, key, propsx }) =>
            item && <animated.div className={`${styles.showMenuWrapper} ${menuStatus && styles.showMenuAppend}`} style={propsx}>
              <ShowsMenu setMenuStatus={setMenuStatus} menuStatus={menuStatus} />
            </animated.div>)}

          {location.pathname !== "/" && <div onClick={() => { setMenuStatus(!menuStatus) }} className={`${styles.btnShowMenuHolder} ${menuStatus && styles.active}`}><a href="javascript:void();" className={styles.btnShowMenu} > <span className={styles.arrow}><img src={Assets.showMenuArrow} alt="arrow" /></span></a></div>}
        </div>
      </header>
      <div className={styles.appContent}>{children}</div>
      <Footer />
    </div>

  )
}

export default MainLayout;
