export const FETCH_MENU = "FETCH_MENU";
export const FETCH_SHOW_MENU = "FETCH_SHOW_MENU";
export const FETCH_BANNER_IMAGE = "FETCH_BANNER_IMAGE";
export const FETCH_FEATURED_SHOW = "FETCH_FEATURED_SHOW";
export const FETCH_FEATURED_EPISODES = "FETCH_FEATURED_EPISODES";
export const FETCH_ALL_SHOWS = "FETCH_ALL_SHOWS";
export const LOAD_MORE_SHOWS = "LOAD_MORE_SHOWS";
export const FETCH_ALL_EPISODES = "FETCH_ALL_EPISODES";
export const LOAD_MORE_EPISODES = "LOAD_MORE_EPISODES";
export const FETCH_SHORT_VIDEOS = "FETCH_SHORT_VIDEOS";
export const LOAD_MORE_SHORT_VIDEOS = "LOAD_MORE_SHORT_VIDEOS";
export const FETCH_SHOW_DETAILS = "FETCH_SHOW_DETAILS";
export const LOAD_MORE_SHOW_DETAILS = "LOAD_MORE_SHOW_DETAILS";
export const FETCH_EPISODE_DETAILS = "FETCH_EPISODE_DETAILS";
export const FETCH_EPISODE_GALLERY = "FETCH_EPISODE_GALLERY";
export const UPLOAD_IMAGE = "UPLOAD_IMAGE";
export const FETCH_SHOW_GALLERY = "FETCH_SHOW_GALLERY";
export const CLEANUP_SHOW_DETAILS = "CLEANUP_SHOW_DETAILS";
export const FETCH_SCHEDULES = "FETCH_SCHEDULES";
export const FETCH_COUNTRY_LIST = "FETCH_COUNTRY_LIST";
export const FETCH_SHOWS_LIST = "FETCH_SHOWS_LIST";

export const FETCH_SEARCH_RESULTS = "FETCH_SEARCH_RESULTS";
export const FETCH_SEARCH_MORE_RESULTS = "FETCH_SEARCH_MORE_RESULTS";

export const FETCH_SEARCH_PAGE_RESULTS = "FETCH_SEARCH_PAGE_RESULTS";
export const FETCH_SEARCH_PAGE_MORE_RESULTS = "FETCH_SEARCH_PAGE_MORE_RESULTS";

export const FETCH_WAFFLE_MENU = "FETCH_WAFFLE_MENU";
export const FETCH_PRIVACY_POLICY = "FETCH_PRIVACY_POLICY";
export const FETCH_COOKIE_POLICY = "FETCH_COOKIE_POLICY";
export const FETCH_TERMS = "FETCH_TERMS";
export const FETCH_ABOUTUS = "FETCH_ABOUTUS";
export const FETCH_TITLE = "FETCH_TITLE";


