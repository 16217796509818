import { combineReducers } from 'redux';
import menuReducer from './menuReducer';
import showsMenuReducer from './showsMenuReducer';
import bannerReducer from './bannerReducer';
import featuredShowsReducer from './featuredShowsReducer';
import featuredEpisodeReducer from './featuredEpisodesReducer';
import showReducer from './showReducer';
import episodeReducer from './episodeReducer';
import shortVideoReducer from './shortVideoReducer';
import showDetailsReducer from './showDetailsReducer';
import episodeDetailsReducer from './episodeDetailsReducer';
import showGalleryReducer from './showGalleryReducer';
import episodeGalleryReducer from './episodeGalleryReducer';
import scheduleReducer from './scheduleReducer';
import countryReducer from './countryReducer';
import searchReducer from './searchReducer';
import searchResultsReducer from './searchResultsReducer';

import waffleMenuReducer from './waffleMenuReducer';
import commonpageReduce from './commonpageReducer';
import privacyReducer from './privacyReducer';
import cookieReducer from './cookieReducer';
import aboutusReducer from './aboutusReducer';
import uploadsReducer from './uploadsReducer';
import titleReducer from './titleReducer';

export default combineReducers({
    menu: menuReducer,
    showMenu: showsMenuReducer,
    banner: bannerReducer,
    featuredShows: featuredShowsReducer,
    featuredEpisode: featuredEpisodeReducer,
    showList: showReducer,
    episodeList: episodeReducer,
    shortVideo: shortVideoReducer,
    showDetails: showDetailsReducer,
    episodeDetails: episodeDetailsReducer,
    showGallery: showGalleryReducer,
    episodeGallery: episodeGalleryReducer,
    schedule: scheduleReducer,
    countrtlist: countryReducer,
    searchResult: searchReducer,
    searchResults: searchResultsReducer,
    waffleMenu: waffleMenuReducer,
    privacyPolicy: privacyReducer,
    cookiePolicy: cookieReducer,
    termsConditions: commonpageReduce,
    aboutUs: aboutusReducer,
    uploadList: uploadsReducer,
    title: titleReducer
});