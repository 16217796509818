import React from 'react';
import Menu from '../Menu';

const Header = () => {

    return (
        <>
        <Menu/>
        </>
    )   
}

export default Header;