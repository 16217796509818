import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { fetchSearchResult } from "../../actions/searchAction";
import styles from "./Search.module.scss";
import { debounce, map } from "lodash";
import { Dropdown } from "react-bootstrap";
import Assets from '../Layouts/Assets';
import { Link } from "react-router-dom";

const categories = {
  "all": 'All',
  "programs": 'Shows',
  "episodes": 'Episodes'
}
const SearchBar = ({ fetchSearchResult, searchResult, setWrapOpen }) => {
  const [debounced, setDebounced] = useState(null);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [type, setType] = useState("all");
  const [searchDropDown, setSearchDropDown] = useState(false);

  const cancelSearch = () => {
    if (debounced) {
      debounced.cancel();
    }
  };

  const debounceSearch = () => {
    const timer = setTimeout(() => {
      setSearchDropDown(true)
      fetchSearchResult(search, type);
      setLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  };

  const handleChange = (query) => {
    setSearch(query);
    if (query.length >= 3) {
      setLoading(true);
      cancelSearch();
      var debouncedState = debounce(debounceSearch, 150);
      setDebounced(debouncedState);
    }
  };

  let history = useHistory();

  useEffect(() => {
    setLoading(true);
    debounceSearch();
  }, [type]);

  useEffect(() => {
    if (!loading &&
      searchResult?.length > 0) {
      document.body.classList.add('search-opened');
    }
    if (searchResult?.length === 0) {
      document.body.classList.remove('search-opened');
    }
  }, [loading, searchResult]);

  const onkeyHandle = (e) => {
    if (e.code === 'Enter') {
      e.preventDefault()
      setWrapOpen(false)
      history.push(`/search/${search}`)
    }
  }

  return (
    <div className={styles.HeaderSearch}>

      <Dropdown onSelect={(e) => setType(e)} className={styles.ddlAll}>
        <Dropdown.Toggle variant="success" id="dropdown-basic">
          {categories[type]}
        </Dropdown.Toggle>

        <Dropdown.Menu  >
          {map(categories, (value, key) => <Dropdown.Item key={key} eventKey={key}>{value}</Dropdown.Item>)}
        </Dropdown.Menu>
      </Dropdown>

      <div className={styles.searchInputWrap}>
        <input
          autoFocus
          type="text"
          placeholder="Search"
          onChange={(e) => handleChange(e.target.value)}
          onKeyPress={(key) => onkeyHandle(key)}
          value={search}
        />
        <div className={styles.searchListWrap}>
          {loading && search.length >= 3 && <div className={styles.loading}><img src={Assets.preloader} alt="data" /></div>}

          {!loading &&
            searchDropDown &&
            searchResult &&
            searchResult.length > 0 &&
            search.length >= 3 &&
            searchResult.map(({ title, url, type, image, programSlug }) => (

              <div
                className={styles.searchList}
              >
                <Link
                  onClick={() => {
                    const timer = setTimeout(() => {
                      setSearchDropDown(false)
                    }, 100);
                    return () => clearTimeout(timer);
                  }}
                  to={type === "episodes" ? `/${programSlug}/${url}` : `/${url}`} className="full_link"></Link>
                <div className={styles.list_panel}>
                  <figure
                    style={{ backgroundImage: `url(${image})` }}
                  ></figure>
                  <div className={styles.list_content}>
                    <strong>{title}</strong>
                    {type === "programs" ? (
                      <span> Shows</span>
                    ) : (
                      <span>{type}</span>
                    )}
                  </div>
                </div>

              </div>

            ))}
          {/* { !loading && searchResult.length === 0 && search.length === 0 && <span style={{color:'red'}}>NO DATA</span>} */}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { searchResult: state?.searchResult?.result };
};

export default connect(mapStateToProps, { fetchSearchResult })(SearchBar);
