import swplogo from '../../assets/swpals3Dlogo.png';
import playbutton   from '../../assets/play.png';
import pausebutton   from '../../assets/pause.png';
import showDetailsBanner from '../../assets/showdetailsbanner.png';

import swworldLogo from '../../assets/swworld.svg';
import swmediaLogo from '../../assets/swmedia.svg';
import swprayerLogo from '../../assets/swprayer.png';
import swtidingsLogo from '../../assets/swtidings.svg';
import arrow from '../../assets/arrow.svg';
import upload from '../../assets/upload.svg';
import gallery from '../../assets/gallery.svg';

import episode1 from '../../assets/episode1.png';
import episode2 from '../../assets/episode2.png';

import share from '../../assets/share.svg';
import showMenuArrow from '../../assets/show-menu-arrow.svg';

import myshalomLogo from '../../assets/icon-shalom.svg';
import squareDots from '../../assets/icon-square-dots.svg';
import searchIcon from '../../assets/icon-search.svg';
import searchCloseIcon from '../../assets/icon-search-close.svg';
import preloader from '../../assets/preloader.gif';

const Assets = {
    swplogo,
    playbutton,
    showDetailsBanner,
    playbutton,
    swworldLogo,
    swmediaLogo,
    swprayerLogo,
    swtidingsLogo,
    pausebutton,
    episode1,
    episode2,
    arrow,
    upload,
    gallery,
    share,
    showMenuArrow,
    myshalomLogo,
    squareDots,
    searchIcon,
    searchCloseIcon,
    preloader
}

export default Assets;